import { createRouter, createWebHistory } from "vue-router";

//check if user is logged in
const loggedIn = () => {
  const user = localStorage.getItem("auth__user");
  const token = localStorage.getItem("auth__token");

  return (
    user !== null && token !== null && user !== "undefined" && token !== "undefined"
  );
};

//redirect to home if logged in
const authGuard = (to, from, next) => {
  if (loggedIn()) {
    next("/");
  } else {
    next();
  }
};

const authChildRoute = (prop) => [
  {
    path: "/login",
    name: prop + ".login",
    component: () => import("../pages/Auth/SignIn"),
    beforeEnter: authGuard,
  },
  {
    path: "/register",
    name: prop + ".register",
    component: () => import("../pages/Auth/SignUp.vue"),
  },
  {
    path: "confirm-mail",
    name: prop + ".confirmmail",
    component: () => import("../pages/Auth/ConfirmMail"),
  },
  {
    path: "confirm-mobile-number",
    name: prop + ".confirm-mobile-number",
    component: () => import("../pages/Auth/MobileVerification"),
  },
  {
    path: "reset-email",
    name: prop + ".reset-email",
    component: () => import("../pages/Auth/ResetEmail"),
  },
  {
    path: "reset-mobile-number",
    name: prop + ".reset-mobile-number",
    component: () => import("../pages/Auth/ResetMobileNumber"),
  },
  {
    path: "/forgot-password",
    name: prop + ".forgot-password",
    component: () => import("../pages/Auth/ForgotPassword.vue"),
  },
  {
    path: "/reset-password/:token",
    name: prop + ".reset-password",
    component: () => import("../pages/Auth/ResetPassword"),
  },
  {
    path: "/terms-conditions",
    name: prop + ".terms-and-conditions",
    component: () => import("../pages/Admin/TermsAndConditions"),
  },
];

const mainChildRoute = (prop, mode = false) => [
  {
    path: "",
    name: prop + ".home",
    meta: { name: "Home" },
    component: () => import("../pages/Home"),
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("../pages/Errors/500-server"),
  },
];
const courseChildRoute = (prop, mode = false) => [
  {
    path: "list",
    name: prop + ".list",
    meta: { name: "Courses List" },
    component: () => import("../pages/Course/CoursesList"),
    props: (route) => ({ page: parseInt(route.query.page) || 1 }),
  },
  {
    path: "admin-list/:course_type",
    name: prop + ".admin-list",
    meta: { auth: true, name: "List Courses for Admin" },
    component: () => import("../pages/Admin/Courses/List"),
    props: (route) => ({ page: parseInt(route.query.page) || 1 }),
  },
  {
    path: "registered-list",
    name: prop + ".registered-list",
    meta: { auth: true, name: "Registered Courses List" },
    component: () => import("../pages/Course/RegisteredCoursesList"),
    props: (route) => ({ page: parseInt(route.query.page) || 1 }),
  },
  {
    path: "draft-list",
    name: prop + ".draft-list",
    meta: { auth: true, name: "List Draft Courses" },
    component: () => import("../pages/Course/DraftCoursesList"),
    props: (route) => ({ page: parseInt(route.query.page) || 1 }),
  },
  {
    path: "details/:id",
    name: prop + ".details",
    meta: { name: "Course Details" },
    component: () => import("../pages/Course/CourseDetails"),
  },
  {
    path: "/details/:id",
    name: prop + ".registered-details",
    meta: { auth: true, name: "Course Details" },
    component: () => import("../pages/Course/RegisteredCourseDetails"),
    props: (route) => ({
      lessoneNumber: parseInt(route.query.lessoneNumber) || 0,
    }),
  },
  {
    path: "/create-course",
    name: prop + ".create",
    meta: { auth: true, name: "Course Create" },
    component: () => import("../pages/Course/Create"),
  },
  {
    path: "/update-course/:id",
    name: prop + ".update",
    meta: { auth: true, name: "Course Update" },
    component: () => import("../pages/Course/Update"),
  },
  {
    path: "/draft-course/:id",
    name: prop + ".draft",
    meta: { auth: true, name: "Course draft" },
    component: () => import("../pages/Course/Draft"),
  },
];

const nutritionPlansChildRoute = (prop, mode = false) => [
  {
    path: "/nutrition_plans/:followup_type",
    name: prop + ".index",
    meta: { name: "Nutrition Plans" },
    component: () => import("../pages/NutritionPlans/Pricing"),
  },
  {
    path: "/create-plan",
    name: prop + ".create",
    meta: { auth: true, name: "Nutrition Plans Create" },
    component: () => import("../pages/NutritionPlans/Create"),
  },

  {
    path: "list-types/:status",
    name: prop + ".list-types",
    meta: { auth: true, name: "Nutrition Plans List" },
    component: () => import("../pages/NutritionPlans/ListTypes"),
    props: (route) => ({ page: parseInt(route.query.page) || 1 }),
  },
  {
    path: "list-type-details/:id",
    name: prop + ".type-details",
    meta: { auth: true, name: "Nutrition Plans List Type Details" },
    component: () => import("../pages/NutritionPlans/PlanTypeDetails"),
    props: (route) => ({ page: parseInt(route.query.page) || 1 }),
  },
  {
    path: "/update-nutrition-plan/:id",
    name: prop + ".update",
    meta: { auth: true, name: "NutritionPlans Update" },
    component: () => import("../pages/NutritionPlans/Update"),
  },

  {
    path: "list/:plan_type",
    name: prop + ".list",
    meta: { auth: true, name: "Nutrition Plans List" },
    component: () => import("../pages/NutritionPlans/List"),
    props: (route) => ({ page: parseInt(route.query.page) || 1 }),
  },
  {
    path: "submit-plan/:plan_type_id/:plan_id",
    name: prop + ".submit-plan",
    meta: { auth: true, name: "Submit Nutrition  Plan Form" },
    component: () => import("../pages/NutritionPlans/PlanForm"),
  },
  {
    path: "plan-pay/:plan_type",
    name: prop + ".plan-pay",
    meta: { name: "Nutrition Plan Ceckout" },
    component: () => import("../pages/NutritionPlans/Ceckout"),
  },
  {
    path: "plan-details/:plan_id",
    name: prop + ".plan-details",
    meta: { auth: true, name: "Nutrition Plan Details" },
    component: () => import("../pages/NutritionPlans/PlanDetails"),
  },
];

const workoutPlansChildRoute = (prop, mode = false) => [
  {
    path: "list-plans/:exercise_location",
    name: prop + ".index",
    meta: { name: "Workout Plans" },
    component: () => import("../pages/WorkoutPlans/Pricing"),
  },
  {
    path: "/create-workout-plan",
    name: prop + ".create",
    meta: { auth: true, name: "Workout Plans Create" },
    component: () => import("../pages/WorkoutPlans/Create"),
  },
  {
    path: "list-workout-plans/:exercise_location",
    name: prop + ".list-workout-plans",
    meta: { auth: true, name: "Workout Plans List" },
    component: () => import("../pages/WorkoutPlans/ListPlans"),
    props: (route) => ({ page: parseInt(route.query.page) || 1 }),
  },
  {
    path: "/update-workout-plan/:id",
    name: prop + ".update",
    meta: { auth: true, name: "WorkoutPlans Update" },
    component: () => import("../pages/WorkoutPlans/Update"),
  },
  {
    path: "workout-plan-pay/:plan_id",
    name: prop + ".workout-plan-pay",
    meta: { name: "Workout Plan Checkout" },
    component: () => import("../pages/WorkoutPlans/Checkout"),
  },
  {
    path: "workout-plan-details/:plan_id",
    name: prop + ".workout-plan-details",
    meta: { auth: true, name: "Workout Plan Details" },
    component: () => import("../pages/WorkoutPlans/PlanDetails"),
  },
  {
    path: "list-subscriptions/:exercise_location",
    name: prop + ".list-subscriptions",
    meta: { auth: true, name: "Workout Plan Details" },
    component: () => import("../pages/WorkoutPlans/ListSubscriptions"),
  },
];

const userChildRoute = (prop, mode = false) => [
  {
    path: "profile/:user_id",
    name: prop + ".profile",
    meta: { auth: true, name: "user profile" },
    component: () => import("../pages/User/Profile"),
  },
  {
    path: "omar",
    name: prop + ".omar",
    meta: { name: "Omar Details" },
    component: () => import("../pages/User/InstructorDetails"),
  },
  {
    path: "invoices/:payment_type",
    name: prop + ".invoices",
    meta: { auth: true, name: "user invoices" },
    component: () => import("../pages/User/Invoices"),
  },
  {
    path: "plans/:plan_type",
    name: prop + ".plans",
    meta: { auth: true, name: "user plans" },
    component: () => import("../pages/User/Plans"),
  },
  {
    path: "update-info",
    name: prop + ".update-info",
    meta: { auth: true, name: "Update User Informations" },
    component: () => import("../pages/User/UpdateInfo"),
  },
  {
    path: "workout-subscription-details/:subscription_id",
    name: prop + ".workout-plan-details",
    meta: { auth: true, name: "User Workout Plan Details" },
    component: () => import("../pages/WorkoutPlans/UserSubscriptionDetails"),
  },
  {
    path: "should-update-info",
    name: prop + ".should-update-info",
    meta: { auth: true, name: "User Should Update Info" },
    component: () => import("../pages/User/ShouldUpdateInfo"),
  },
];

const cartChildRoute = (prop, mode = false) => [
  {
    path: "",
    name: prop + ".list",
    meta: { name: "cart list" },
    component: () => import("../pages/Cart"),
  },
  {
    path: "/checkout",
    name: prop + ".checkout",
    meta: { name: "cart checkout" },
    component: () => import("../pages/Checkout"),
  },
];

const discountChildRoute = (prop, mode = false) => [
  {
    path: "/create-discount",
    name: prop + ".create",
    meta: { auth: true, name: "create new discount code" },
    component: () => import("../pages/Admin/Discount/Create"),
  },
  {
    path: "/list",
    name: prop + ".list",
    meta: { auth: true, name: "discount codes list" },
    component: () => import("../pages/Admin/Discount/List"),
  },

  {
    path: "/update/:discount_id",
    name: prop + ".update",
    meta: { auth: true, name: "update discount codes" },
    component: () => import("../pages/Admin/Discount/Update"),
  },
];

const administratorChildRoute = (prop, mode = false) => [
  {
    path: "control",
    name: prop + ".control",
    meta: { auth: true, name: "Course or Plan Create" },
    component: () => import("../pages/Admin/Control"),
  },
];

const paymentsChildRoute = (prop, mode = false) => [
  {
    path: "list/:payment_type",
    name: prop + ".list",
    component: () => import("../pages/Admin/Payments/ListAllPayments"),
    props: (route) => ({ page: parseInt(route.query.page) || 1 }),
  },
  {
    path: "payment-details/:payment_id",
    name: prop + ".payment-details",
    meta: { auth: true, name: "Payment Details" },
    component: () => import("../pages/Admin/Payments/PaymentDetails"),
  },
  {
    path: "plan-payment-details/:payment_id",
    name: prop + ".plan-payment-details",
    meta: { auth: true, name: "Payment Details" },
    component: () => import("../pages/Admin/Payments/PlanPaymentDetails"),
  },
  {
    path: "workout-plan-payment-details/:payment_id",
    name: prop + ".workout-plan-payment-details",
    meta: { auth: true, name: "Workout Plan Payment Details" },
    component: () =>
      import("../pages/Admin/Payments/WorkoutPlanPaymentDetails"),
  },
];

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../pages/Errors/404"),
  },
  {
    path: "/notauthorized",
    name: "NotAuthorized",
    component: () => import("../pages/Errors/401"),
  },
  // {
  //   path: "/server-error",
  //   name: "ServerError",
  //   component: () => import("../pages/Errors/500-server"),
  // },
  {
    path: "/auth",
    name: "auth",
    component: () => import("../layouts/Empty"),
    children: authChildRoute("auth"),
  },
  {
    path: "/",
    name: "main",
    component: () => import("../layouts/Empty"),
    children: mainChildRoute("main"),
  },
  {
    path: "/course",
    name: "course",
    component: () => import("../layouts/Empty"),
    children: courseChildRoute("course"),
  },
  {
    path: "/nutrition_plans",
    name: "nutrition_plans",
    component: () => import("../layouts/Empty"),
    children: nutritionPlansChildRoute("nutrition_plans"),
  },
  {
    path: "/workout_plans",
    name: "workout_plans",
    component: () => import("../layouts/Empty"),
    children: workoutPlansChildRoute("workout_plans"),
  },
  {
    path: "/administrator",
    name: "administrator",
    component: () => import("../layouts/Empty"),
    meta: { auth: true },
    children: administratorChildRoute("administrator"),
  },
  {
    path: "/payments",
    name: "payments",
    component: () => import("../layouts/Empty"),
    meta: { auth: true },
    children: paymentsChildRoute("payments"),
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../layouts/Empty"),
    children: userChildRoute("user"),
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../layouts/Empty"),
    children: cartChildRoute("cart"),
  },
  {
    path: "/discount",
    name: "discount",
    component: () => import("../layouts/Empty"),
    meta: { auth: true },
    children: discountChildRoute("discount"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !loggedIn()) {
    // Store the intended route
    localStorage.setItem("intendedRoute", to.fullPath);

    return next("/login");
  } else {
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   // Check if the destination route is the maintenance page
//   if (to.path !== "/maintenance") {
//     // Redirect to the maintenance page
//     next("/maintenance");
//   } else {
//     // Continue with the route
//     next();
//   }
// });

export default router;
